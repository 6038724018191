<template>
  <b-list-group
    class="w-100"
  >
    <b-list-group-item
      v-for="(user, index) in users"
      :key="user.id"
      :active="activeIndex === index"
      class="d-flex align-items-center border-end rounded my-25"
      style="cursor: pointer;border:none;"
      @click="selectUser(user, index)"
    >
      <b-avatar
        :src="user.avatar"
        size="32px"
        variant="light"
      />
      <span class="font-small-3 font-weight-bold ml-2">
        {{ user.name }}
      </span>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BAvatar,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: null,
    }
  },
  computed: {

  },
  methods: {
    selectUser(user, index) {
      this.activeIndex = index
      this.$emit('user-selected', user)
    },
  },
}
</script>

<style scoped>
.b-tabs.card {
  border: none;
}
#__BVID__150___BV_tab_button__ {
    display: flex;
    align-items: center;
    justify-content: start !important;
}
</style>
