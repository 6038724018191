const users = [
  {
    id: 1,
    name: 'Alice Johnson',
    avatar: 'https://randomuser.me/api/portraits/women/9.jpg',
  },
  {
    id: 2,
    name: 'Bob Smith',
    avatar: 'https://randomuser.me/api/portraits/men/5.jpg',
  },
  {
    id: 3,
    name: 'Charlie Brown',
    avatar: 'https://randomuser.me/api/portraits/men/59.jpg',
  },
  {
    id: 4,
    name: 'Diana Prince',
    avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
  },
  {
    id: 5,
    name: 'Ethan Hunt',
    avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
  },
  {
    id: 6,
    name: 'John',
    avatar: 'https://randomuser.me/api/portraits/women/9.jpg',
  },
  {
    id: 7,
    name: 'Bovh',
    avatar: 'https://randomuser.me/api/portraits/men/5.jpg',
  },
  {
    id: 8,
    name: 'lierown',
    avatar: 'https://randomuser.me/api/portraits/men/59.jpg',
  },
  {
    id: 9,
    name: 'Dnce',
    avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
  },
  {
    id: 10,
    name: 'hyEtht',
    avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
  },
]

export default users
