<template>
  <div class="search-container">
    <div class="avatar-wrapper">
      <b-avatar
        src="https://randomuser.me/api/portraits/men/1.jpg"
        size="36px"
      />
    </div>
    <b-input-group-append class="border rounded my-1">
      <b-input-group class="search-box">
        <b-button
          variant="light"
          class="search-button"
        >
          <b-icon icon="search" />
        </b-button>
        <b-form-input
          v-model="searchQuery"
          placeholder="Search..."
          class="border-0"
          @input="$emit('search', searchQuery)"
        />
      </b-input-group>
    </b-input-group-append>
  </div>
</template>

<script>
import {
  BAvatar, BInputGroup, BFormInput, BInputGroupAppend, BButton, BIcon,
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BIcon,
  },
  methods: {
    data() {
      return {
        searchQuery: '',
      }
    },
  },
}
</script>

  <style scoped>
  .search-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
  }
  .search-box {
    flex-grow: 1;
  }

  .search-button {
    background: none;
    border: none;
  }
  </style>
