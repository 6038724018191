<template>
  <!-- The section now takes full viewport height -->
  <section class="p-0 vh-100">
    <!-- The card is set to 100% height -->
    <b-card
      class="p-0"
      style=" height:90%"
    >
      <b-row>
        <b-col
          cols="4"
          md="4"
          class="border-bottom border-right"
        >
          <SearchBar
            @search="handleSearch"
          />
        </b-col>
        <b-col
          cols="8"
          md="8"
        >
          <UserProfile
            :selected-user="selectedUser"
          />
        </b-col>
      </b-row>
      <b-row class="h-100">
        <!-- Set the left column to scroll with full height -->
        <b-col
          cols="4"
          md="4"
          class=""
          style="overflow-y: auto; height:85%"
        >
          <h4 class="text-primary font-medium-3 font-weight-bold mt-1">
            Chat
          </h4>
          <Contacts
            v-if="previousChatUsers.length > 0 && selectedUser"
            :users="previousChatUsers"
            :selected-user="selectedUser"
            :user-messages="userMessages[selectedUser.id]"
            @user-selected="selectUser"
          />
          <div
            v-else
            class="text-muted"
          >
            No previous chats available
          </div>
          <br>
          <h4 class="text-primary font-medium-3 font-weight-bold mt-1">
            Contacts
          </h4>
          <Contacts
            v-if="filterUserCheck"
            :users="filteredUsers"
            @user-selected="selectUser"
          />
          <div
            v-else
            class="text-muted"
          >
            Contact Not Found
          </div>
        </b-col>
        <!-- Right column takes full height -->
        <b-col
          cols="8"
          md="8"
          class="p-0 h-100"
        >
          <ChatUI
            v-if="selectedUser"
            :selected-user="selectedUser"
            :user-messages="userMessages[selectedUser.id] || []"
            @send-message="handleSendMessage"
          />
          <div
            v-else
            class="d-flex align-items-center justify-content-center text-center text-muted mx-1"
            style="background-color: #f1f2f2; height: 85%;"
          >
            Select a contact to view or send messages.
          </div>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import SearchBar from '@/components/chat/SearchBar.vue'
import UserProfile from '@/components/chat/UserProfile.vue'
import Contacts from '@/components/chat/Contacts.vue'
import ChatUI from '@/components/chat/ChatUI.vue'
import users from '@/components/chat/staticUsers'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    SearchBar,
    UserProfile,
    Contacts,
    ChatUI,
  },
  data() {
    return {
      selectedUser: null,
      users,
      searchTerm: '',
      userMessages: {},
    }
  },
  computed: {
    filteredUsers() {
      return this.users.filter(user => user.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
    },
    filterUserCheck() {
      return this.filteredUsers.length > 0
    },
    previousChatUsers() {
      return this.users.filter(user => this.userMessages[user.id] && this.userMessages[user.id].length > 0)
    },
  },
  methods: {
    selectUser(user) {
      this.selectedUser = user
    },
    handleSearch(term) {
      this.searchTerm = term
    },
    handleSendMessage({ userId, message }) {
      if (!this.userMessages[userId]) {
        this.$set(this.userMessages, userId, [])
      }
      this.userMessages[userId].push(message)
    },
  },
}
</script>

<style scoped>
.card-body {
  padding-top: .25rem !important;
  /* The min-height rule is removed in favor of full height classes */
}
</style>
