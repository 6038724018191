<template>
  <b-row class="d-flex align-items-center h-100 pl-1">
    <span class="">
      <b-avatar
        :src="selectedUser.avatar"
        size="36px"
        variant="light"
        class=""
      /></span>
    <b-col
      cols="10"
      md="10"
      class=""
    >
      <span>
        {{ selectedUser.name }}
      </span>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar, BCol, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BCol,
    BRow,
  },
  props: {
    selectedUser: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
}
</script>
