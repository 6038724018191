<template>
  <div
    class="p-2 mx-1 d-flex flex-column justify-content-between"
    style=" background-color:#f1f2f2 ; height:85%"
  >
    <b-row
      ref="messagesContainer"
      no-gutters
      class=" height-class px-1 "
      style="max-height: fit-content;"
    >
      <b-col
        md="12"
      >
        <b-card-body
          class="d-flex flex-column justify-content-between py-1 "
        >
          <!-- Messages Container -->
          <b-card-text
            ref="messagesContainer"
          >
            <div
              v-for="(message, index) in messages"
              :key="index"
              class="w-100 mb-2"
              :class="{'d-flex justify-content-end': message.isSent, 'd-flex justify-content-start': !message.isSent}"
            >
              <div
                style="max-width: 50%;"
              >
                <div
                  class="d-flex flex-row justify-content-end"
                >
                  <div
                    class="bg-primary m-50 message-bubble user-message"
                  >
                    <div
                      class="text-white text-wrap font-small-3 mb-50 d-flex justify-content-center text-break"
                    >
                      {{ message.text }}
                    </div>
                  </div>
                  <b-avatar
                    :src="selectedUser.avatar"
                    class="ml-75 mt-1"
                  />
                </div>
                <div class="d-flex flex-row-reverse align-items-center mr-2">
                  <small class="text-nowrap text-gray-50 font-small-1 mr-4">{{ message.time }}</small>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- Input Box -->
        <div class="d-flex align-item-start bg-white justify-content-around">
          <b-input-group-append class="d-flex p-50 rounded w-100">
            <b-form-input
              v-model="newMessage"
              placeholder="Type your message here..."
              style="background:transparent; border: none; outline:none"
              @keyup.enter="sendMessage"
            />
            <b-button
              variant="primary"
              class="btn-icon"
              :disabled="newMessage === ''"
              @click="sendMessage"
            >
              <feather-icon icon="SendIcon" />
            </b-button>
          </b-input-group-append>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCardBody, BRow, BCol, BFormInput, BButton, BAvatar, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  components: {
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BInputGroupAppend,
    BAvatar,
  },
  props: {
    selectedUser: {
      type: Object,
      required: true,
    },
    userMessages: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      newMessage: '',
    }
  },
  computed: {
    messages() {
      return this.userMessages
    },
  },
  watch: {
    messages: {
      handler() {
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollToBottom()
    })
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        const message = {
          text: this.newMessage,
          isSent: true,
          time: new Date().toLocaleTimeString(),
        }
        this.$emit('send-message', { userId: this.selectedUser.id, message })
        this.newMessage = ''
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    },
    scrollToBottom() {
      const container = this.$refs.messagesContainer
      if (container) {
        container.scrollTop = container.scrollHeight
      }
    },
  },
}
</script>

<style scoped>
.message-bubble {
  background-color: #f8f9fa;
  border-radius: 15px;
  padding: 9px 9px;
  position: relative;
  margin: 8px 0;
}

.message-bubble.user-message {
  margin-left: auto;
  overflow-x: none;
}
.card-body{
  padding: .75rem;
}

.message-bubble::before {
  content: '';
  position: absolute;
  left: -8px;
  top: 15px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #f8f9fa;
}

.message-bubble.user-message::before {
  left: auto;
  right: -7px;top: 10px;
  border-right: none;
  border-left: 8px solid #1f58b5;
}

.height-class {
  overflow-y: auto;
}

.height-class::-webkit-scrollbar {
    width: 6px;
    margin-left: 2px;

}
.height-class::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 50px;
  margin-left: 2px;
}

.height-class::-webkit-scrollbar-thumb {
  background-color: #4c92fc;
  border-radius: 50px;
  margin-left: 2px;
}

</style>
